/**
 * The validation state of a field is stored as a 2-character string (in the
 * field-state attribute of the html tag.
 */
export class FieldState {
    constructor(state, props) {
        this.is_valid = false;      // has the field passed validation?
        this.has_validated = false;     // has the field been green?

        if (typeof state === 'string') {
            this.fromString(state);
        } else if (state) {
            this.copy_from(state);
        }
        if (props) Object.assign(this, props);
    }

    /**
     * Is the string a valid state string?
     * @param s
     */
    static valid(s) {
        if (typeof s !== 'string') return false;
        if (s.length !== 2) return false;
        return /[V_][G_]/.test(s);
    }

    copy_from(s) {
        this.has_validated = s.has_validated;
        this.is_valid = s.is_valid;
    }

    fromString(s) {
        this.is_valid = s[0] === 'V';
        this.has_validated = s[1] === 'G';
        return this;
    }

    toString() {
        let res = '';
        res += this.is_valid ? 'V' : '_';
        res += this.has_validated ? 'G' : '_';
        return res;
    }

    set_state(item) {
        item.setAttribute('field-state', this.toString());
    }

    static get_state(item) {
        return new FieldState(item.getAttribute('field-state'));
    }

    static diffstates(s1, s2) {
        let res = '';
        if (s1.is_valid !== s2.is_valid) {
            res += ` is_valid: ${s1.is_valid} -> ${s2.is_valid}\n`;
        } else {
            res += ` is_valid: ${s1.is_valid}\n`;
        }
        if (s1.has_validated !== s2.has_validated) {
            res += ` has_validated: ${s1.has_validated} -> ${s2.has_validated}\n`;
        } else {
            res += ` has_validated: ${s1.has_validated}\n`;
        }
        return res;
    }

    static change_state(item, props) {
        let change_to_make = null;
        const cur_state = FieldState.get_state(item);
        // console.log('cur-state:', cur_state)
        // console.log('change-state:', props);

        // set the new is_valid status on creation
        const new_state = new FieldState(cur_state, {
            is_valid: props.is_valid,
            has_validated: cur_state.has_validated || props.is_valid || !!props.has_validated
        });
        const is_valid_has_changed = cur_state.is_valid !== new_state.is_valid;
        const has_validated_has_changed = cur_state.has_validated !== new_state.has_validated;

        if (has_validated_has_changed && !new_state.is_valid) {
            change_to_make = false;  // set field to false
        }
        if (new_state.has_validated && is_valid_has_changed) {
            change_to_make = new_state.is_valid;  // set field to new value
        }
        // console.log(FieldState.diffstates(cur_state, new_state));
        // console.log('change-to-make:', change_to_make, new_state);
        new_state.set_state(item);
        return change_to_make;
    }
}
