import dk from "@norsktest/dkdj";
import {_ctrl} from "./keydefs";
import {FieldState} from "./field-state";
import $ from 'jquery';
import {change_item_validation} from "./change_item_validation";


export class Validator {
    constructor(selector) {
        this.selector = selector;
        const self = this;
        this.set_initial_state();
        $(this.selector).on({
            focus: function () {  // F-action
                // console.log('focus')
                // change_state($(this), {visited: true});  // 18
            },
            keydown: function (e) {
                // backspace/delete does not cause keypress to be fired so
                // we need to remember the value before the key was pressed
                // console.log('keydown')
                e.target.dataset.keydownval = e.target.value;
            },
            input: function (e) {
                // console.log('input');
                if (!e.target.dataset.keydownval) self.validate(this, 'force');
            },
            // change: function (e) {
            //     console.log('change');
            // },
            keyup: function (e) {
                // console.log('keyup')
                // check if the event caused the value of the input to change..
                const lastval = e.target.dataset.keydownval;
                const curval = e.target.value;
                const changed = lastval !== curval;
                delete e.target.dataset.keydownval;     // cleanup
                if (changed) self.validate(this, 'change');  // only validate if the value has changed
            },
            // The keypress event is fired when a key that produces a character value is pressed down.
            keypress: function (e) {
                // FIXME: deprecated (https://developer.mozilla.org/en-US/docs/Web/API/Document/keypress_event)
                //        use beforeinput or keydown instead
                // console.log('keypress')
                const ch = e.which;
                return _ctrl(ch) || self.legal_charcode(ch, e.target);
            },
            blur: function (e) {
                // console.log('blur')
                delete e.target.dataset.keydownval;     // cleanup
                self.validate(this, 'force');
                // ajax_validation(this);
            },
            validate: function () {
                // to make testing easier..
                self.validate(this, 'force');
            },
            prevalidate: function () {
                // for use as re-validation on page refresh $(':input').trigger('prevalidate');
                if ($(this).val()) {
                    self.validate(this, 'force');
                    // ajax_validation(this);
                }
            }
        });
    }

    // return true iff val is not falsy (override either this or legal_charcode, or both)
    validate_value(val) {
        return !!val;
    }

    // by default any input is legal
    legal_charcode(c, target) {
        return true;
    }

    set_initial_state() {
        const self = this;
        $(this.selector).each(function () {
            const $this = $(this);
            const current_value = $this.val();
            const initial_state = new FieldState();

            if (current_value) {
                // found a value, let's validate it..
                const is_valid = self.validate_value(current_value);
                this.dataset.value = is_valid;
                initial_state.is_valid = is_valid;
                initial_state.has_validated = true;
                $this.addClass(`validated-${is_valid}`);
            }
            // console.log("set-initial-state:validate-value", initial_state, self);
            initial_state.set_state(this);
        });
    }

    validation_change(item, newstate) {
        const form = item.closest('form');
        dk.trigger(form, 'validation-change', item, newstate);
    }


    /*
     *  Should be called with the DOM item to be validated
     */
    validate(item, event_type) {
        if (item.dataset.validation === 'pause') return;
        const $item = $(item);
        const current_value = $item.val();
        const is_valid = this.validate_value(current_value);
        // is it a required field
        const is_required = $item.prop('required');
        // is the required attribute satisified?
        const not_required_and_empty = !is_required && !current_value;

        let change_to_make = null;

        if (not_required_and_empty) {
            // clear validation
            delete item.dataset.valid;
            $item.removeClass('validated-true');
            $item.removeClass('validated-false');
            (new FieldState()).set_state(item);
            return null;
        } else if (is_valid) {
            change_to_make = FieldState.change_state(item, {
                is_valid: true,
            });
        } else {
            if (event_type === 'force') {
                change_to_make = FieldState.change_state(item, {
                    is_valid: false,
                    has_validated: true
                });
            }
            if (event_type === 'change') {
                const curstate = FieldState.get_state(item);
                if (curstate.has_validated) {
                    change_to_make = FieldState.change_state(item, {
                        is_valid: false,
                        has_validated: true
                    });
                } else {
                    change_to_make = FieldState.change_state(item, {
                        is_valid: false,
                        has_validated: false
                    });
                }
            }
        }

        if (change_to_make === null) return null;  // nothing to do
        change_item_validation(item, change_to_make);
        this.validation_change(item, change_to_make);
        return change_to_make;
    }
}
