import {version} from "./version";
import {Wizard, WizardProgress, WizardStep} from "./wizard";
import {enable_validation} from "./validation";
import * as validators from "./validators";
import {DkForm} from "./form-class";
import {Validator} from "./validator-base";
import * as keydefs from "./keydefs";
import { enable_password_strength } from "./password-strength";

enable_password_strength();

const dkforms = {
    // insert exported names here
    version,
    Wizard,
    WizardProgress,
    WizardStep,
    enable_validation,
    DkForm,
    Validator,
    validators,
    keydefs,
    enable_password_strength,
};

export default dkforms;
