
/**
 * 
 * @param {string} password
 * 
 * Return the number of bits of entropy in password.
 * (technically only valid for randomly generated passwords...) 
 * 
 */
export function entropy(p) {
    let cset = 0;
    if (p.search(/\d/) >= 0) cset += 10;
    if (p.search(/[a-z]/) >= 0) cset += 26;
    if (p.search(/[A-Z]/) >= 0) cset += 26;
    if (p.search(/[æøåÆØÅ]/) >= 0) cset += 6;
    if (p.search(/[-_.:,;<>?"#*$%&\/()!@~\s]/) >= 0) cset += 22;

    return Math.log2(Math.pow(cset, p.length))
}

/**
 * 
 * @param {int} lo 
 * @param {int} val 
 * @param {int} hi 
 * @returns {int} the value clamped to [lo..high]
 */
function _clamp(lo, val, hi) {
    if (val < lo) return lo;
    if (val > hi) return hi;
    return val;
}

/**
 * 
 * @param {string} password 
 * @returns {int}  0..100
 * 
 * The strength of a password (0 low, 100 high).
 */
export function password_strength(p) {
    let val = 0;
    if (!p) return 0;
    if (/^\w{1,5}\d+$/i.test(p)) return 10;
    return _clamp(0, Math.round(entropy(p)), 100);
}


export function enable_password_strength() {
    const lo = 55;
    const med = 75;

    function password_color(strength) {
        if (strength < lo) return 'red';
        if (strength < med) return 'orange';
        if (strength >= med) return 'green';
    }

    $(document).on('ready', function () {
        $('.dvPasswordConfirmation > progress').each(function (i, prog) {
            const $this = $(this);
            const pwd = $this.prev();
            const icon = $this.next();
            pwd.on('keyup', function () {
                const password = pwd.val();
                const strength = password_strength(password);
                const color = password_color(strength);
                $this.val(strength);
                $this.text(`${strength}%`);
                $this.css('accent-color', color);
                icon[0].value = color === 'green' ? "check:fw" : "null";
            });
        });
    });
};