import {
    _colon,
    _ctrl,
    _digit,
    _dot, _eqchar, _in,
    _letter,
    _minus,
    _space,
    _underscore
} from "./keydefs";
import {Validator} from "./validator-base";


const _zip = (...rows) => [...rows[0]].map((_, c) => rows.map(row => row[c]));
const multiply_reduce = (avec, bvec) => _zip(avec, bvec).map(([a, b]) => a * b).reduce((a, b) => a + b, 0);


export class TextValidator extends Validator {

}

export class MinLengthValidator extends Validator {
    constructor(minlength, selector) {
        super(selector);
        this.minlength = minlength;
    }
    validate_value(val) {
        return val.length >= this.minlength;
    }
}

export class UsernameValidator extends Validator {
    constructor(selector) {
        super(selector || 'input.vUserName');
    }
    legal_charcode(c) {
        return _ctrl(c) || _digit(c) || _letter(c) || _underscore(c) || _minus(c);
    }
}

export class IntegerValidator extends Validator {
    legal_charcode(c) {
        return _digit(c) || _minus(c) || _ctrl(c);
    }
}

export class PositiveIntegerValidator extends Validator {
    legal_charcode(c) {
        return _digit(c) || _ctrl(c);
    }
}

// 09:45
export class TimeValidator extends Validator {
    validate_value(val) {
        if (!val) return false;
        const parts = val.split(':');
        if (parts.length !== 2) return false;
        if (parseInt(parts[0]) > 24) return false;
        return parseInt(parts[1]) <= 59;
    }
    legal_charcode(c) {
        return _digit(c) || _colon(c) || _ctrl(c);
    }
}


export class HourValidator extends Validator {
    validate_value(val) {
        console.log(val);
        if (!val) return false;
        const numval = parseInt(val);
        return !(numval > 24 || numval < 0);
    }
    legal_charcode(c) {
        return _digit(c) || _ctrl(c);
    }
}

export class MinuteValidator extends Validator {
    validate_value(val) {
        if (!val) return false;
        const numval = parseInt(val);
        return !(numval > 59 || numval < 0);
    }
    legal_charcode(c) {
        return _digit(c) || _ctrl(c);
    }
}

export class FloatValidator extends Validator {
    legal_charcode(c) {
        return _digit(c) || _minus(c) || _dot(c) || _ctrl(c);
    }
}

export class PhoneNumberValidator extends Validator {
    constructor(selector) {
        super(selector || 'input.vTelefonInput');
    }
    validate_value(phone) {
        phone = phone.replace(/[-\s]/g, "");
        return /^(\+47)?\d{8}$/.test(phone);
    }
    legal_charcode(c) {
        return _digit(c) || _space(c) || _eqchar(c, '+');
    }
}

export class EmailValidator extends Validator {
    constructor(selector) {
        super(selector || 'input.vEmailInput');
    }
    validate_value(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }
    legal_charcode(c) {
        return !_space(c) && !_in(c, '()#^*=&/;,');
    }
}

/**
 * https://www.brreg.no/om-oss/oppgavene-vare/alle-registrene-vare/om-enhetsregisteret/organisasjonsnummeret/
 */
export class NOOrgNumberValidator extends Validator {
    constructor(selector) {
        super(selector);
    }
    validate_value(orgnum) {
        if (orgnum.length !== 9) return false;

        orgnum = Array.from(orgnum).map(v => parseInt(v, 10));
        const control = orgnum[8];  // last digit
        const vekt = [3, 2, 7, 6, 5, 4, 3, 2];

        const product = multiply_reduce(orgnum.slice(0, 8), vekt);
        const modulo = product % 11;
        if (modulo === 0 && control === 0) {
            return true;
        }
        return control === (11 - modulo);
    }
    legal_charcode(c) {
        return _digit(c);
    }
}


export class PersnrValidator extends Validator {
    constructor(selector) {
        super(selector);
    }
    validate_value(ssn) {
        if (ssn.length !== 11) return false;
        ssn = Array.from(ssn).map(v => parseInt(v, 10));
        const vekt1 = [3,7,6,1,8,9,4,5,2,1,0];
        const vekt2 = [5,4,3,2,7,6,5,4,3,2,1];

        if (multiply_reduce(ssn, vekt1) % 11 !== 0) {
            return false;
        }
        return (multiply_reduce(ssn, vekt2) % 11 === 0);
    }
    legal_charcode(c) {
        return _digit(c);
    }
}
