// import {Widget} from "dk/widgetcore/dk-widget";
/*
 *  WizardProgress is the 'progress bar' for the wizard execution.
 */

export class WizardProgress extends dk.Widget {
    constructor(...args) {
        super({
            type: 'WizardProgress',
            name: null,
            label: '',
            structure: {
                classes: ['panel', 'panel-default', 'placeholder'],
                panel_body: {}
            },
        }, ...args);
    }


    text() {
        let txt, label;

        switch (arguments.length) {
            case 1:
                txt = arguments[0];
                this.panel_body.html(txt);
                break;
            case 2:
                label = arguments[0].trim();
                if (label) label += ": ";
                txt = arguments[1];
                this.panel_body
                    .append($('<b/>').text(label))
                    .append(txt + '<br>');
                break;
        }
    }
    update(data) {
        const self = this;
        this.text('<h3>' + this.label + '</h3>');
        Object.keys(data).forEach(function (key) {
            const val = data[key];
            self.text(val.label, val.value);
        });
    }

    set_state(state) {
        this.widget().addClass(state);
    }

    activate() {
        this.widget().addClass('active');
    }

    deactivate() {
        this.widget().removeClass('active');
    }

    construct() {
        this.widget().attr('category', this.name);
        this.text('<h3>' + this.label + '</h3>');
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////

/*
 *  The dkforms.Wizard class defines where the progress box is located, what the steps are,
 *  and the ordering of the steps (it tells the WizardSteps which are first and last).
 */
export class Wizard extends dk.Widget {
    constructor(...args) {
        super({
            type: 'dkforms-wizard',
            progressbox: null,
            steps: [],
            categories: {},
        }, ...args);
    }
    /*
     *  Prefill the WizardProgress boxes on a re-post.
     */
    prefill_data() {
        dk.info("PREFILLING...");
        this.steps.forEach(function (step) {
            step.nav.progress.update(step.nav.get_data());
        });
    }

    /*
     *  Display form errors and navigate to the first error.
     */
    set_form_errors(errors) {
        const self = this;
        dk.info("set form errors on wizard");
        const firsterr = 999999;
        errors.invalid_categories.forEach(function (category) {
            const step = self.categories[category];
            const firsterr = Math.min(firsterr, step.stepnum);
            step.progress.set_state('error');
        });
        if (firsterr > 0) {
            self.steps[0].nav._deactivate();
            self.steps[firsterr].nav._activate();
        }
    }

    add_nav(stepnum, nav) {
        this.steps[stepnum].nav = nav;
    }

    construct() {
        dk.info("CONSTRUCTING dkforms.Wizard", arguments);
        const self = this;
        this.steps.forEach(function (step, i) {
            if (step.nav) {
                step.nav.progress = WizardProgress.append_to(self.progressbox, {
                    name: name,
                    label: step.label
                });
                step.nav.wizard = self;
                self.categories[step.name] = step.nav;
                step.nav.set_stepnum(i);
            }
        });
    }
}

///////////////////////////////////////////////////////////////////////////////////////////////////


/*
 *  WizardStep represents the individual steps.
 */
export class WizardStep extends dk.Widget {
    constructor(...args) {
        super({
            type: 'WizardStep',
            name: null,
            wizard: null,
            first: false,
            last: false,
            next: null,
            prev: null,
            hide_disabled: true,
            category: undefined,
            field_selector: '[name][id^="id_"]',    // selector passed to jQuery to select category fields.

            structure: {
                classes: ['row', 'subform-navigation'],
                back: {
                    classes: ['col-md-3', 'col-md-offset-3'],
                    prev_btn: {
                        classes: ['btn', 'btn-block'],
                        template: '<button type="button"/>',
                        text: "&laquo; tilbake"
                    }
                },
                forward: {
                    classes: ['col-md-3'],
                    next_btn: {
                        classes: ['btn', 'btn-block', 'btn-primary'],
                        template: '<button type="button"/>',
                        text: "Gå videre &raquo;"
                    }
                }
            },
        }, ...args);
    }

    init() {}

    set_stepnum(n) {
        this.widget().attr('stepnum', 'step-' + n);
        const lastnum = this.wizard.steps.length - 1;
        this.stepnum = n;
        if (n === 0) {
            this.set_first();
        } else {
            this.prev = this.wizard.steps[n-1];
            this.display(false);
        }
        if (n === lastnum) {
            this.set_last();
        } else {
            this.next = this.wizard.steps[n+1];
        }
    }

    set_first() {
        this.first = true;
        this.back.prev_btn.prop('disbaled', true);
        if (this.hide_disabled) this.back.prev_btn.css('visibility', 'hidden');
        this.progress.activate();
    }

    set_last() {
        this.last = true;
        this.forward.next_btn.prop('disabled', true);
        if (this.hide_disabled) this.forward.next_btn.css('visibility', 'hidden');
    }

    construct() {
        this.parent = this.widget().parent();
        dk.info(this.parent.prop('id'));
        if (!this.category) this.category = this.parent.attr('name');
    }

    display(bool) {
        this.parent[bool? 'show': 'hide']();
    }

    /*
     *   Return the data for the fields in this category.
     */

    /*
     *  Override this to use special logic to collect the inputs.
     */
    get_field_list() {
        return this.widget().parent().find(this.field_selector);
    }

    get_field_label(input) {
        const $input = $(input);
        let label = $(`label[for=${$input.prop('id')}]`);
        if (label.length === 0) label = $input.closest('label');
        return label.text();
    }

    /*
     *  Override this to customize getting data from an individual field.
     */
    get_field_data(input) {
        const $input = $(input);
        let value;

        switch ($input.prop('type')) {
            case 'checkbox':
                value = $input.prop('checked')? $input.val(): null;
                break;
            default:
                value = $input.val();
        }
        return {
            id: $input.prop('id'),
            name: $input.attr('name'),
            value: value,
            label: this.get_field_label($input)
        };
    }

    /*
     *  Override get_data() if you want to handle everything yourself.
     *  It should return an object from id: {id:__, value: __, label: __}.
     */
    get_data() {
        const self = this;
        const data = {};
        this.get_field_list().each(function (i, input) {
            const fdata = self.get_field_data(input);
            data[fdata.id] = fdata;
        });
        return data;
    }

    activate() {
        this.parent.find('.subform :input').trigger('prevalidate');
        this.parent.find('.subform :input:first').focus();
        if (this.is_valid()) this.on_valid();
    }

    _activate() {
        this.progress.activate();
        this.display(true);
        this.activate();
    }

    _deactivate() {
        // sayg?
        if (this.deactivate) this.deactivate();
        this.progress.update(this.get_data());
        this.progress.deactivate();
        this.display(false);
    }

    go_next() {
        if (this.validate_next()) {
            this._deactivate();
            this.next.nav._activate();
        }
    }

    go_prev() {
        if (this.validate_prev()) {
            this._deactivate();
            this.prev.nav._activate();
        }
    }

    handlers(){
        const self = this;

        this.back.prev_btn.on('click', this.FN('go_prev'));
        this.forward.next_btn.on('click', this.FN('go_next'));

        if (this.validation_handlers) this.validation_handlers();

        // go to next section when pressing enter in last input field.
        const category = this.widget().closest('.category');
        category.find('input:last').on('keypress', function (e) {
            if (e.which === 13) {
                $(this).trigger('prevalidate');
                self.go_next();
            }
        });

        this.parent.find('input').on('change', function () {
            if (self.is_valid()) self.on_valid();
        });
    }

    is_valid() {
        return true;
    }

    on_valid() {}

    validate_next() {
        dk.warn("You need to override SubformNavigation.validate_next()!");
        return true;
    }

    validate_prev() {
        dk.warn("You need to override SubformNavigation.validate_prev()!");
        return true;
    }

}
