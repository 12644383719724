import {FieldState} from "./field-state";
import {change_item_validation} from "./change_item_validation";


class DkField {
    constructor(name, dom) {
        this.name = name;
        this.dom = dom;
        this._original_help = this.help;
    }

    get value() {
        return dk.$(this.dom).find(':input').val();
    }
    get input() {
        return dk(`#id_${this.name}`);
    }
    get help() {
        const help_block = dk.$(this.dom).find('.help-block');
        return help_block.html();
    }
    get uses_dkforms_validation() {
        return this.input.getAttribute('field-state') !== null;
    }

    get valid() {
        return this.input.dataset.valid === 'true';
    }
    set valid(val) {
        if (this.uses_dkforms_validation) {
            change_item_validation(this.input, val);
        }
    }

    set error(msg) {
        const help_block = dk.$(this.dom).find('.help-block');
        if (!!msg) {
            // have error message
            help_block
                .removeClass('helptext')
                .addClass('errordescr')
                .html(msg);
            this.valid = false;
        } else {
            // error message is empty
            help_block
                .removeClass('errordescr')
                .addClass('helptext')
                .html(this._original_help);
            this.valid = true;
        }
    }
}

export class DkForm {
    constructor(formselector) {
        this.form = dk.$(formselector);

        this.fields = Object.fromEntries([...this.form.find('[field]')].map(f => [
            f.getAttribute('field'), new DkField(f.getAttribute('field'), f)
        ]));
        this.field_names = Object.keys(this.fields);
    }
}
