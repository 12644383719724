import $ from "jquery";

export function change_item_validation(item, change_to_make) {
    item.dataset.valid = change_to_make;
    if (change_to_make === true) {
        delete item.dataset.validation_error;
    }
    const addclass = `validated-${change_to_make}`;
    const rmclass = `validated-${!change_to_make}`;

    const $item = $(item);
    if ($item.hasClass(rmclass)) $item.removeClass(rmclass);
    if (!$item.hasClass(addclass)) $item.addClass(addclass);
}
