export const _digit = (ch) => ch >= 48 && ch <= 57;
export const _ASCII = (ch) => ch >= 65 && ch <= 90;
export const _ascii = (ch) => ch >= 97 && ch <= 122;
export const _letter = (ch) => _ASCII(ch) || _ascii(ch);
export const _underscore = (ch) => ch === 95;
export const _colon = (ch) => ch === 58;
export const _minus = (ch) => ch === 45;
export const _dot = (ch) => ch === 46;
export const _ctrl = (ch) => ch < 32;    // arrow-keys, tab-key, etc.
export const _space = (ch) => ch === 32;

export function _in(ch, c) { // c should be a string. returns true iff character ch is in string c.
    for (let i = 0; i < c.length; i++) {
        if (ch === c.charCodeAt(i)) {
            return true;
        }
    }
    return false;
}

export const _eqchar = (ch, c) => ch === c.charCodeAt(0);

export function _editkey(e) {
    if (e.ctrlKey) {
        switch (e.keyCode) {
            case 67:
                return 'copy';
            case 86:
                return 'paste';
            case 88:
                return 'cut';
        }
    }
    return false;
}
