/*
 Client side validation of datakortet.core.forms, and access to some html5 features.
 */
/*
 *  FIXME: this documentation is for static/js/dkforms3.js and needs to be updated
 *  A validator function that removes some of the tediousness of
 *  validate-as-you-type fields.
 *
 *  Usage, this will ensure that only characters defined in legal_charcode
 *  can be entered into the field.  The field will also get an extra
 *  attribute ``validated`` that is initially undefined.  The field is
 *  validated for every key-press the user performs, and also when the
 *  user navigates away from the field (if the value has changed).  If
 *  the user enters a valid value (checked by ``validate_value``), then
 *  a jQuery ``validation-change`` event is triggered, passing the new
 *  validation state (true) as an argument.  Higher level validation
 *  can listen for this trigger. The ``validated`` attribute is also
 *  set to 'true' (a string value!).
 *
 *  Once an input has been validated to ``true``, it will trigger
 *  ``validation-change`` events every time a user change causes the
 *  input to go from valid to invalid (and vice versa). Similarly for the
 *  ``validated`` attribute.
 *
 *  Validation will also be triggered if a user leaves a required field
 *  without entering a value.
 *
 *  ``validate_value`` and/or ``legal_charcode`` can be omitted, and any
 *  value will validate (empty required fields will still not be valid).
 *
 *  Example usage::
 *
 *       _validator({
 *            $: 'input.vTelefonInput',
 *            validate_value: function (phone) {
 *                phone = phone.replace(/[-\s]/g, "");
 *                return /^(\+47)?\d{8}$/.test(phone);
 *            },
 *            legal_charcode: function (c) {
 *               return _digit(c) || _space(c) || _eqchar(c, '+');
 *            }
 *        });
 *
 *  In addition, you can assign a function to the global
 *
 *        dkforms.ajax[<selector>]
 *
 *  where selector is the same as the `$` value in the _validator.
 *  This function will get called on blur events, and is intended
 *  for server side validation through ajax calls.
 *
 *  to give the user instant feedback, you can add css rules similar to
 *  the following::
 *
 *      .validated-false {
 *          // very light red
 *          background-color: rgba(255, 192, 203, 0.61) !important;
 *      }
 *      .validated-true {
 *          // very light green
 *          background-color: #a6eda6 !important;
 *      }
 *
 *  or less in-your-face:
 *
 *     [validated=false] { border-color: #ff6b0e !important; }
 *     [validated=true] { border-color: #a6eda6 !important; }
 *
 *
 */


import {
    _colon,
    _ctrl,
    _digit,
    _dot,
    _eqchar,
    _in,
    _letter,
    _minus,
    _space,
    _underscore
} from "./keydefs";
import {
    EmailValidator,
    FloatValidator,
    IntegerValidator,
    MinLengthValidator, NOOrgNumberValidator,
    PersnrValidator,
    PhoneNumberValidator,
    PositiveIntegerValidator, TextValidator,
    TimeValidator,
    UsernameValidator,
    HourValidator,
    MinuteValidator,
} from "./validators";

/**
 * Enable validation on all input in form_selector
 *
 * @param form_selector
 */
export function enable_validation(form_selector) {
    console.log("FORMSELECTOR:", form_selector);
    const theform = $(form_selector);

    new TextValidator(`${form_selector} input.vTextInput`);
    new UsernameValidator(`${form_selector} input.vUserName`);

    // only allow numbers in integer(-looking) fields
    new PositiveIntegerValidator(`${form_selector} input.vPositiveIntegerInput, ${form_selector} input.vPostnummerInput, ${form_selector} input.vKidNumber`);

    // only allow numbers and minus in signed integer fields
    new IntegerValidator(`${form_selector} input.vIntegerInput, ${form_selector} input.vSmallIntegerInput`);

    // only allow numbers, dots, and minus in Decimal fields
    new FloatValidator(`${form_selector} input.vDecimalInput`);

    // 09:45
    new TimeValidator(`${form_selector} input.vTimeInput`);

    new HourValidator(`${form_selector} input.vHourInput`);
    new MinuteValidator(`${form_selector} input.vMinuteInput`);

    new MinLengthValidator(dk.$(`${form_selector} input.vFirstNameInput`).attr('minlength') || 1, `${form_selector} input.vFirstNameInput` );
    new MinLengthValidator(dk.$(`${form_selector} input.vLastNameInput`).attr('minlength') || 1, `${form_selector} input.vLastNameInput` );
    new PhoneNumberValidator(`${form_selector} input.vTelefonInput`);
    new EmailValidator(`${form_selector} input.vEmailInput`);
    new PersnrValidator(`${form_selector} input.vSSN`);
    new NOOrgNumberValidator(`${form_selector} input.vOrgNumber`);
    new MinLengthValidator(dk.$(`${form_selector} input.vPasswordInput`).attr('minlength') || 1, `${form_selector} input.vPasswordInput` );

    return true;
}
